import React, { useState, useEffect } from 'react';
import { trackEvent } from 'ddc-track-event';
import { useRequestData } from 'wsm-common-data';
import PropTypes from 'prop-types';
import GeoRange from './GeoRange';
import LocationInput from './LocationInput';

const FacetGeoLocation = ({ facetTitle }) => {
	const { widgetName, windowId } = useRequestData();
	const geoRadiusFilter = window.DDC.InvData.getAppliedFilters().find(
		(filter) => filter.id === 'geoRadius'
	);
	const geoRadiusValue = geoRadiusFilter
		? geoRadiusFilter.values[0].value
		: '0';
	const { geoZip } = window.DDC.InvData.getGeoData();
	const [range, setRange] = useState(geoRadiusValue);
	const [location, setLocation] = useState(geoZip);

	useEffect(() => {
		if (range !== geoRadiusValue) {
			setRange(geoRadiusValue);
		}
		if (location[0] !== geoZip[0]) {
			setLocation(geoZip);
		}
	}, [range, geoRadiusValue, location, geoZip]);

	const updateRange = (e) => {
		const newRange = e.target.value;
		if (newRange !== range) {
			setRange(newRange);
			window.DDC.InvData.setFilterSelections({
				filterName: 'geoRadius',
				filterValues: [newRange],
				filterValue: [newRange],
				filterTitleText: facetTitle,
				element: e.target,
				isSelected: true,
				isSlider: false,
				isChip: true
			});
		}
		trackEvent(widgetName, windowId, {
			action: 'applied',
			element: 'GeoRadius selection',
			elementCTA: 'GeoRadius',
			fieldValue: newRange,
			result: 'Results updated with facet added'
		});
	};

	const updateLocation = (input, target) => {
		if (input !== location) {
			setLocation([input]);
			window.DDC.InvData.setFilterSelections({
				filterName: 'geoZip',
				filterValues: [input],
				filterValue: [input],
				element: target,
				isSelected: true,
				isSlider: false,
				isChip: false
			});
			window.localStorage.setItem('userLocation', input);
		}
	};

	return (
		<React.Fragment>
			<GeoRange range={range} updateRange={updateRange} />
			<LocationInput
				location={location}
				updateLocation={updateLocation}
			/>
		</React.Fragment>
	);
};

FacetGeoLocation.propTypes = {
	facetTitle: PropTypes.string
};

export default FacetGeoLocation;

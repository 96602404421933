import React from 'react';
import PropTypes from 'prop-types';
import { useLabels } from 'wsm-common-data';

const GeoRange = ({ range, updateRange }) => {
	const { geoRadiusValues } = window.DDC.InvData.getGeoDataPrefs();
	const labels = useLabels();

	const getRadiusValues = () => {
		const optionsArr = [];

		geoRadiusValues.split(',').forEach(value => {
			const isAnyDistance = value === '0';

			optionsArr.push({
				value,
				label: isAnyDistance
					? `${labels.get('ANY_DISTANCE')}`
					: `${value} ${labels.get('MILES')}`
			});
		});

		return optionsArr;
	};

	return (
		<select
			className="form-control font-size-ios-zoom-override"
			aria-label={labels.get('ZIP_CODE_RANGE_DROPDOWN')}
			onChange={updateRange}
			value={range}
		>
			{getRadiusValues(geoRadiusValues).map(({ value, label }) => (
				<option key={value} value={value}>
					{label}
				</option>
			))}
		</select>
	);
};

GeoRange.propTypes = {
	range: PropTypes.string.isRequired,
	updateRange: PropTypes.func.isRequired
};

export default GeoRange;

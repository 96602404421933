import React from 'react';
import PropTypes from 'prop-types';
import ClosestDealer from './ClosestDealer';

const ClosestDealerWrapper = ({ closestAccounts, isRenderPortal }) => {
	const { data: closestDealers, appliedParams } = closestAccounts || {};

	if (!closestDealers?.length) {
		return <></>;
	}
	const closestDealer = closestDealers[0];

	const { postalCode } = appliedParams;

	const openModal = () => {
		window?.DDC?.pubsub?.publish('location-modal/show-modal');
	};

	return (
		<>
			<ClosestDealer
				closestDealer={closestDealer}
				userPostalCode={postalCode}
				onClick={openModal}
				containerClassNames={[
					'text-left',
					'facet-list-group panel panel-default',
					isRenderPortal
						? 'd-flex flex-wrap align-items-center flex-gap-row-1-rem'
						: 'mb-4'
				]}
				isRenderPortal={isRenderPortal}
			/>
		</>
	);
};

ClosestDealerWrapper.propTypes = {
	isRenderPortal: PropTypes.string.isRequired,
	closestAccounts: PropTypes.shape({
		data: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string.isRequired
			})
		),
		appliedParams: PropTypes.shape({
			postalCode: PropTypes.string.isRequired
		})
	})
};

export default ClosestDealerWrapper;
